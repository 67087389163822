
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElButton, ElForm } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "add-branch-org",
  props: {
    orgcode: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.modalForm.orgcode = this.orgcode;
  },
  data() {
    return {
      isvalid: false,
      addbranchbuttonloader: false,
      modalForm: {
        orgcode: "",
        branchname: "",
        branchcode: ""
      },
      addBranchFormRules: {
        orgcode: [
          {
            required: true,
            message: "Байгууллагын код хоосон байна.",
            trigger: "blur"
          },
          {
            min: 1,
            max: 10,
            message: "Урт хамгийн ихдээ 10-аас бага байна.",
            trigger: "blur"
          }
        ],
        branchname: {
          required: true,
          message: "Салбарын нэр хоосон байна.",
          trigger: "blur"
        },
        branchcode: {
          required: true,
          message: "Салбарын код хоосон байна.",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    fillOrgCode() {
      this.modalForm.orgcode = this.orgcode;
    },
    async saveBranch() {
      if (this.modalForm.orgcode.length < 5) return;
      if (this.modalForm.branchcode.length < 1) return;
      if (this.modalForm.branchname.length < 1) return;
      this.addbranchbuttonloader = true;
      await ApiService.post(`orgbranch`, {
        params: {
          orgcode: this.modalForm.orgcode,
          branchcode: this.modalForm.branchcode,
          branchname: this.modalForm.branchname
        }
      })
        .then(resp => {
          this.addbranchbuttonloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.$emit("refreshevent");
              this.hidemodelbutton?.$el.click();
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.addbranchbuttonloader = false;
          Swal.fire({
            text: "Салбар нэмэхэд алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    }
  },
  setup() {
    const addbranchorgmodalref = ref<InstanceType<typeof ElForm>>();
    const hidemodelbutton = ref<InstanceType<typeof ElButton>>();
    const formSubmit = () => {
      addbranchorgmodalref.value?.validate(async valid => {
        return !!valid;
      });
    };

    const clearField = () => {
      addbranchorgmodalref.value?.resetFields();
    };

    return {
      addbranchorgmodalref,
      hidemodelbutton,
      clearField,
      formSubmit
    };
  }
});
