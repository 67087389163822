
import { defineComponent, ref } from "vue";
import CustomerData from "@/data/customer";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElButton, ElForm } from "element-plus";
import moment from "moment";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "add-org",
  data() {
    return {
      isvalid: false,
      orgaddbuttonloader: false,
      modalForm: {
        orgcode: "",
        orgname: "",
        cstartdate: "",
        cenddate: "",
        desc: "",
        selectOrgType: CustomerData[0]["statusTypeAddOrg"],
        selectVal: "04",
        selectOrgStat: CustomerData[0]["orgStatus"],
        selectStatVal: "01"
      },
      loginFormRules: {
        orgcode: [
          {
            required: true,
            message: "Байгууллагын код хоосон байна.",
            trigger: "blur"
          },
          {
            min: 1,
            max: 10,
            message: "Урт хамгийн ихдээ 10-аас бага байна.",
            trigger: "blur"
          }
        ],
        orgname: {
          required: true,
          message: "Байгууллагын нэр хоосон байна.",
          trigger: "blur"
        },
        cstartdate: {
          required: true,
          message: "Гэрээ эхэлсэн өдөр хоосон байна.",
          trigger: "blur"
        },
        cenddate: {
          required: true,
          message: "Гэрээ дуусах өдөр хоосон байна.",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    async saveOrg() {
      if (this.modalForm.orgcode.length < 5) return;
      if (this.modalForm.orgname.length < 2) return;
      if (!this.modalForm.cstartdate) return;
      if (!this.modalForm.cenddate) return;
      this.orgaddbuttonloader = true;
      await ApiService.post(`org`, {
        params: {
          orgcode: this.modalForm.orgcode,
          orgname: this.modalForm.orgname,
          orgtypecode: this.modalForm.selectVal,
          contractstatuscode: this.modalForm.selectStatVal,
          cstartdate: moment(String(this.modalForm.cstartdate)).format(
            "YYYY/MM/DD"
          ),
          cenddate: moment(String(this.modalForm.cenddate)).format(
            "YYYY/MM/DD"
          ),
          desc: this.modalForm.desc
        }
      })
        .then(resp => {
          this.orgaddbuttonloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.$emit("refreshevent");
              this.hidemodalbutton?.$el.click();
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.orgaddbuttonloader = false;
          Swal.fire({
            text: "Байгууллага нэмэхэд алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    }
  },
  setup() {
    const addorgmodalref = ref<InstanceType<typeof ElForm>>();
    const hidemodalbutton = ref<InstanceType<typeof ElButton>>();
    const formSubmit = () => {
      addorgmodalref.value?.validate(async valid => {
        return !!valid;
      });
    };

    const clearField = () => {
      addorgmodalref.value?.resetFields();
    };

    return {
      addorgmodalref,
      hidemodalbutton,
      clearField,
      formSubmit
    };
  }
});
