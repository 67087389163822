const CustomerData: object = [
  {
    statusTypeBranch: [
      {
        selectValBranch: "07",
        selectLabelBranch: "Бүгд"
      },
      {
        selectValBranch: "03",
        selectLabelBranch: "Банк"
      },
      {
        selectValBranch: "04",
        selectLabelBranch: "ББСБ"
      },
      {
        selectValBranch: "05",
        selectLabelBranch: "ХЗХ"
      },
      {
        selectValBranch: "06",
        selectLabelBranch: "Бусад"
      }
    ],
    statusType: [
      {
        selectVal: "07",
        selectLabel: "Бүгд"
      },
      {
        selectVal: "03",
        selectLabel: "Банк"
      },
      {
        selectVal: "04",
        selectLabel: "ББСБ"
      },
      {
        selectVal: "05",
        selectLabel: "ХЗХ"
      },
      {
        selectVal: "06",
        selectLabel: "Бусад"
      }
    ],
    statusTypeAddOrg: [
      {
        selectVal: "03",
        selectLabel: "Банк"
      },
      {
        selectVal: "04",
        selectLabel: "ББСБ"
      },
      {
        selectVal: "05",
        selectLabel: "ХЗХ"
      },
      {
        selectVal: "06",
        selectLabel: "Бусад"
      }
    ],
    orgStatus: [
      {
        selectStatVal: "01",
        selectStatLabel: "Гэрээ хүчинтэй"
      },
      {
        selectStatVal: "02",
        selectStatLabel: "Гэрээ дууссан"
      },
      {
        selectStatVal: "03",
        selectStatLabel: "Гэрээ цуцлагдсан"
      }
    ]
  }
];

export default CustomerData;
