
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CustomerData from "@/data/customer";
import AddOrg from "@/components/modals/orgAddModal.vue";
import AddBranchOrg from "@/components/modals/branchOrgAddModal.vue";
import { Modal } from "bootstrap";
import { ElButton, ElForm, ElNotification } from "element-plus";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "Organization",
  components: { AddBranchOrg, AddOrg },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Байгууллагын жагсаалт");
    });
    const editorgmodalref = ref<InstanceType<typeof ElForm>>();
    const editbranchmodalref = ref<InstanceType<typeof ElForm>>();
    const submitValidateEditOrgButton = ref<InstanceType<typeof ElButton>>();
    const submitValidateEditBranchButton = ref<InstanceType<typeof ElButton>>();
    const formSubmit = () => {
      editorgmodalref.value?.validate(async valid => {
        if (valid) submitValidateEditOrgButton.value?.$el.click();
        else return valid;
      });
    };
    const formSubmitBranch = () => {
      editbranchmodalref.value?.validate(async valid => {
        if (valid) submitValidateEditBranchButton.value?.$el.click();
        else return valid;
      });
    };
    const clearField = () => {
      editorgmodalref.value?.resetFields();
    };
    const clearFieldBranch = () => {
      editbranchmodalref.value?.resetFields();
    };
    return {
      editorgmodalref,
      submitValidateEditOrgButton,
      editbranchmodalref,
      submitValidateEditBranchButton,
      formSubmit,
      formSubmitBranch,
      clearFieldBranch,
      clearField
    };
  },
  data() {
    return {
      addbranchorgcode: "",
      editbranchmodal: {
        savebuttonloading: false,
        modal: {} as Modal,
        responsemodal: {} as Modal,
        modalform: {
          orgcode: "",
          branchname: "",
          oldbranchcode: "",
          branchcode: ""
        },
        modalformrule: {
          branchname: {
            required: true,
            message: "Салбарын нэр хоосон байна.",
            trigger: "blur"
          },
          branchcode: {
            required: true,
            message: "Салбарын код хоосон байна.",
            trigger: "blur"
          }
        }
      },
      deletebranchmodal: {
        savebuttonloading: false,
        modal: {} as Modal,
        branchcode: "",
        orgcode: "",
        branchname: ""
      },
      deleteorgmodal: {
        savebuttonloading: false,
        modal: {} as Modal,
        orgcode: "",
        orgname: ""
      },
      editorgmodal: {
        savebuttonloading: false,
        modal: {} as Modal,
        responsemodal: {} as Modal,
        modalform: {
          orgcode: "",
          orgname: "",
          cstartdate: "",
          cenddate: "",
          desc: "",
          selectOrgType: CustomerData[0]["statusTypeAddOrg"],
          selectVal: "",
          selectOrgStat: CustomerData[0]["orgStatus"],
          selectStatVal: ""
        },
        modalformrule: {
          orgcode: [
            {
              required: true,
              message: "Байгууллагын код хоосон байна.",
              trigger: "blur"
            },
            {
              min: 1,
              max: 10,
              message: "Урт хамгийн ихдээ 10-аас бага байна.",
              trigger: "blur"
            }
          ],
          orgname: {
            required: true,
            message: "Байгууллагын нэр хоосон байна.",
            trigger: "blur"
          },
          selectVal: {
            required: true,
            message: "Заавал сонгоно уу.",
            trigger: "blur"
          },
          selectStatVal: {
            required: true,
            message: "Заавал сонгоно уу.",
            trigger: "blur"
          },
          cstartdate: {
            required: true,
            message: "Гэрээ эхэлсэн өдөр хоосон байна.",
            trigger: "blur"
          },
          cenddate: {
            required: true,
            message: "Гэрээ дуусах өдөр хоосон байна.",
            trigger: "blur"
          }
        }
      },
      branchSelected: false,
      loadingOrgTable: true,
      loadingOrgBranchTable: true,
      page: 1,
      pageSize: 10,
      pageBranch: 1,
      pageSizeBranch: 10,
      customerSearch: "",
      customerBranchSearch: "",
      tableData: [],
      tableDataModal: [],
      tableDataBranch: [],
      tableDataBranchModal: [],
      statusTypeOrg: CustomerData[0]["statusType"],
      statusTypeBranch: CustomerData[0]["statusTypeBranch"],
      selectOrgList: [],
      selectValOrg: "",
      selectVal: "07",
      selectValBranch: "07"
    };
  },
  mounted() {
    this.refresh();
    this.editorgmodal.modal = new Modal(
      this.$refs.editOrgModal as HTMLDivElement
    );
    this.editorgmodal.responsemodal = new Modal(
      this.$refs.editOrgRespModal as HTMLDivElement
    );
    this.deleteorgmodal.modal = new Modal(
      this.$refs.deleteOrgModal as HTMLDivElement
    );
    this.editbranchmodal.modal = new Modal(
      this.$refs.editBranchModal as HTMLDivElement
    );
    this.editbranchmodal.responsemodal = new Modal(
      this.$refs.editBranchRespModal as HTMLDivElement
    );
    this.deletebranchmodal.modal = new Modal(
      this.$refs.deleteBranchModal as HTMLDivElement
    );
  },
  methods: {
    refresh() {
      this.getOrg();
      this.getOrgBranch();
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** branch delete modal functions
     ***************************************
     ***************************************
     ***************************************/
    deletebranchmodalshowbutton(event) {
      this.deletebranchmodal.branchcode = event.currentTarget.getAttribute(
        "data-branchcode"
      );
      this.deletebranchmodal.branchname = event.currentTarget.getAttribute(
        "data-branchname"
      );
      this.deletebranchmodal.orgcode = event.currentTarget.getAttribute(
        "data-orgcode"
      );
      this.deletebranchmodal.modal.show();
    },
    deletebranchmodalhidebutton() {
      this.deletebranchmodal.modal.hide();
    },
    async deleteBranch() {
      this.deletebranchmodal.savebuttonloading = true;
      await ApiService.delete(`orgbranch`, {
        params: {
          orgcode: this.deletebranchmodal.orgcode,
          branchcode: this.deletebranchmodal.branchcode
        }
      })
        .then(resp => {
          this.deletebranchmodal.savebuttonloading = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.getOrgBranch();
              this.deletebranchmodalhidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.deletebranchmodal.savebuttonloading = false;
          Swal.fire({
            text: "Салбар устгахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** branch edit modal functions
     ***************************************
     ***************************************
     ***************************************/
    editbranchshowbutton(row) {
      this.editbranchmodal.modalform.orgcode = row.orgcode;
      this.editbranchmodal.modalform.branchname = row.branchname;
      this.editbranchmodal.modalform.branchcode = row.orgbranchcode;
      this.editbranchmodal.modalform.oldbranchcode = row.orgbranchcode;
      this.editbranchmodal.modal.show();
    },
    editbranchhidebutton() {
      this.editbranchmodal.modal.hide();
    },
    editbranchresphidemodal() {
      this.editbranchmodal.responsemodal.hide();
    },
    editbranchrespshowmodal() {
      this.editbranchmodal.responsemodal.show();
    },
    async editBranch() {
      if (this.editbranchmodal.modalform.orgcode.length < 5) return;
      if (this.editbranchmodal.modalform.branchcode.length < 1) return;
      if (this.editbranchmodal.modalform.branchname.length < 1) return;
      this.editbranchmodal.savebuttonloading = true;
      await ApiService.put(`orgbranch`, {
        params: {
          orgcode: this.editbranchmodal.modalform.orgcode,
          branchcode: this.editbranchmodal.modalform.branchcode,
          oldbranchcode: this.editbranchmodal.modalform.oldbranchcode,
          branchname: this.editbranchmodal.modalform.branchname
        }
      })
        .then(resp => {
          this.editbranchmodal.savebuttonloading = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.getOrgBranch();
              this.editbranchresphidemodal();
              this.editbranchhidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.editbranchmodal.savebuttonloading = false;
          Swal.fire({
            text: "Салбар засахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** org delete modal functions
     ***************************************
     ***************************************
     ***************************************/
    deleteorgmodalshowbutton(event) {
      this.deleteorgmodal.orgcode = event.currentTarget.getAttribute(
        "data-orgcode"
      );
      this.deleteorgmodal.orgname = event.currentTarget.getAttribute(
        "data-orgname"
      );
      this.deleteorgmodal.modal.show();
    },
    deleteorgmodalhidebutton() {
      this.deleteorgmodal.modal.hide();
    },
    async deleteOrg() {
      this.deleteorgmodal.savebuttonloading = true;
      await ApiService.delete(`org`, {
        params: {
          orgcode: this.deleteorgmodal.orgcode
        }
      })
        .then(resp => {
          this.deleteorgmodal.savebuttonloading = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.getOrg();
              this.deleteorgmodalhidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.deleteorgmodal.savebuttonloading = false;
          Swal.fire({
            text: "Байгууллага устгахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** org edit modal functions
     ***************************************
     ***************************************
     ***************************************/
    editorgshowbutton(row) {
      this.editorgmodal.modalform.orgcode = row.orgcode;
      this.editorgmodal.modalform.orgname = row.orgname;
      this.editorgmodal.modalform.selectVal = row.orgtypecode;
      this.editorgmodal.modalform.selectStatVal = row.contractstatuscode;
      this.editorgmodal.modalform.cstartdate = row.cstartdate;
      this.editorgmodal.modalform.cenddate = row.cenddate;
      this.editorgmodal.modalform.desc = row.description;
      this.editorgmodal.modal.show();
    },
    editorghidebutton() {
      this.editorgmodal.modal.hide();
    },
    editorgresphidemodal() {
      this.editorgmodal.responsemodal.hide();
    },
    editorgrespshowmodal() {
      this.editorgmodal.responsemodal.show();
    },
    startDatePicker(val) {
      this.editorgmodal.modalform.cstartdate = moment(val).format("YYYY/MM/DD");
    },
    endDatePicker(val) {
      this.editorgmodal.modalform.cenddate = moment(val).format("YYYY/MM/DD");
    },
    async editOrg() {
      this.editorgmodal.savebuttonloading = true;
      await ApiService.put(`org`, {
        params: {
          orgcode: this.editorgmodal.modalform.orgcode,
          orgname: this.editorgmodal.modalform.orgname,
          orgtypecode: this.editorgmodal.modalform.selectVal,
          contractstatuscode: this.editorgmodal.modalform.selectStatVal,
          cstartdate: moment(this.editorgmodal.modalform.cstartdate).format(
            "YYYY/MM/DD"
          ),
          cenddate: moment(this.editorgmodal.modalform.cenddate).format(
            "YYYY/MM/DD"
          ),
          desc: this.editorgmodal.modalform.desc
        }
      })
        .then(resp => {
          this.editorgmodal.savebuttonloading = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.getOrg();
              this.editorgresphidemodal();
              this.editorghidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.editorgmodal.savebuttonloading = false;
          Swal.fire({
            text: "Байгууллага засахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** org branch component functions
     ***************************************
     ***************************************
     ***************************************/
    handleChangeBranchAddOrgcode(val) {
      this.addbranchorgcode = val;
      this.branchSelected = false;
      setTimeout(() => {
        this.branchSelected = true;
      }, 10);
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    handleChange(value) {
      this.pageSize = value;
    },
    handleCurrentChangeBranch(val) {
      this.pageBranch = val;
    },
    handleChangeBranch(value) {
      this.pageSizeBranch = value;
    },
    async getOrg() {
      await ApiService.get(`org`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            this.selectOrgList = results.response.map(org => ({
              selectValOrg: org.orgcode,
              selectOrgTypeCode: org.orgtypecode,
              selectLabelOrg: org.orgname
            }));
            this.tableData = results.response.map(org => ({
              orgcode: org.orgcode,
              orgname: org.orgname,
              orgtype: org.orgtype,
              orgtypecode: org.orgtypecode,
              cstartdate: org.cstartdate,
              cenddate: org.cenddate,
              contractstatus: org.contractstatus,
              contractstatuscode: org.contractstatuscode,
              datasupplyfreq: org.datasupplyfreq,
              description: org.description,
              searchfield: org.searchfield
            }));
            this.tableDataModal = this.tableData;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingOrgTable = false;
        });
    },
    async getOrgBranch() {
      await ApiService.get(`orgbranch`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            this.tableDataBranch = results.response.map(it => ({
              orgcode: it.orgcode,
              orgbranchcode: it.orgbranchcode,
              branchname: it.branchname,
              isdeleted: it.isdeleted,
              lastupdate: it.lastupdate,
              lastupdateuser: it.lastupdateuser,
              searchfield: it.searchfield
            }));
            this.tableDataBranchModal = this.tableDataBranch;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingOrgBranchTable = false;
        });
    }
  }
});
